export { matchers } from './client-matchers.js';

export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13')];

export const server_loads = [4,2,3];

export const dictionary = {
	"/(user)": [~10,[4]],
	"/(user)/cameras/[camera_id]": [~11,[4]],
	"/(staff)/gridviews": [~5,[2]],
	"/login": [~12],
	"/logout": [~13],
	"/(staff)/settings/cameras": [~6,[2,3]],
	"/(staff)/settings/problem_reports": [~7,[2,3]],
	"/(staff)/settings/system": [~8,[2,3]],
	"/(staff)/settings/users": [~9,[2,3]]
};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};